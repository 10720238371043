import React from "react";
import { links } from "./site";

const pageTitleData = {
  title: "Casos de exito",
  subtitle: "Proyectos revolucionarios más allá de las espectativas del cliente ",
  cta: {
    primary: {
      ...links.contact,
      label: "Escríbenos y conversemos",
    },
    secondary: {
      ...links.newProject,
      label: "Inicia tu proyecto hoy",
    },
  },
};


export const SuccessStoriesPageData = {
  pageTitleData
};
