import { graphql, PageProps } from "gatsby";
import React from "react";
import { Layout } from "../components/layout";
import { PageHeaderSection } from "../components/rebrand/sections/PageHeader/PageHeaderSection";
import { SEO } from "../components/seo";
import { SuccessStoriesPageData } from "../data/successStories";

const { pageTitleData } = SuccessStoriesPageData;

export const pageQuery = graphql`
  query SuccessStoriesPage {
    site {
      siteMetadata {
        pages {
          successStories {
            title
            description
            keywords
          }
        }
      }
    }
  }
`;

interface SuccessStoriesPageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        pages: {
          successStories: Queries.SiteSiteMetadataPagesSuccessStories;
        };
      };
    };
  };
}

export default function SuccessStoriesPage(props: SuccessStoriesPageProps) {
  const { data, location } = props;
  const { site } = data;

  const { title, description, keywords } = site.siteMetadata.pages.successStories;

  const seo = {
    title: title as string,
    description: description as string,
    // keywords: keywords as string[],
    pathname: location.pathname,
  };

  return (
    <Layout>
      <SEO {...seo} />
      <PageHeaderSection title={pageTitleData.title} subtitle={pageTitleData.subtitle} cta={pageTitleData.cta} />
    </Layout>
  );
}
